import { types } from "../constants/types";
const initialState = {
  frameState: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SET_FRAME:
      return { 
        ...state, 
        frameState: !action.payload 
      }
      
    default:
      return {
        ...state
      }
  }
};